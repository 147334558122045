// Variable overrides
.bg-primary {
    background-color:white !important;
}

  .bg-gradient {
    animation: animateBg 14s linear infinite;
    background-image: linear-gradient(45deg,#ff8000,#db5779,#ff8000,#db5779);
    background-size: 300% 100%;
  }
  @keyframes animateBg {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 0%; }
  }


.btn-primary {
    color: #fff;
    background-color:orange;
    border-color:orange;
}
.btn-primary:hover {
    color: #fff;
    background-color:#E96B00;
    border-color:#E96B00;
}
.btn-primary:active {
    color: #fff;
    background-color:#E96B00;
    border-color:#E96B00;
}
.btn-primary:focus {
    color: #fff;
    background-color:#E96B00;
    border-color:#E96B00;
}

.btn-link {
    font-weight: 400;
    color: orange;
    text-decoration: none;
}

.btn-link:hover {
    font-weight: 400;
    color: #E96B00;
    text-decoration: none;
}